import React, {useState} from 'react';
import CodeMirror from '@uiw/react-codemirror';
import {langs} from '@uiw/codemirror-extensions-langs';
import {vscodeDark} from '@uiw/codemirror-theme-vscode';

export const CodeAreaCatalogInfo = () => {
  const [value, setValue] = useState(getSampleCatalogInfo());
  return (
    <CodeMirror
      value={value}
      height='100%'
      maxHeight='600px'
      onChange={setValue}
      theme={vscodeDark}
      editable={false}
      extensions={[langs.yaml()]}
      lang='yaml'
    />
  );
};

export const CodeAreaMkDocs = () => {
  const [value, setValue] = useState(getMkDocsConfig());
  return (
    <CodeMirror
      value={value}
      height='100%'
      maxHeight='800px'
      onChange={setValue}
      theme={vscodeDark}
      editable={false}
      extensions={[langs.yaml()]}
      lang='yaml'
    />
  );
};

function getSampleCatalogInfo() {
  return `
#This is an example catalog-info.yaml to add to an existing repo you wish to register in Pariveda Backstage
#Trailing comments describe the use of all fields
#for option descriptions got to: https://parivedasolutions.atlassian.net/wiki/spaces/PI/pages/471859201/Backstage+Taxonomy
#Select a value for each field based on the complements
#When using a value from comments, do not include quotations
#DO NOT change any text preceding comments or not commented

apiVersion: backstage.io/v1alpha1
kind: Component #use 'Template' if adding a template
metadata:
  name: #name of the IP, dash separated words no spaces, use repo slug if unsure
  description: #description of the IP, cannot be empty
  links:
  - title: Website #optional, remove if there is no associated website
    url: #url to an associated website. this can be sharepoint or confluence
  - title: Slack Channel #optional, remove if there is no associated slack channel with this IP
    url: https://pariveda.slack.com/archives/CHANNELID #replace the channel id with your string
  #if there is no associated website or slack channel, remove links block
  annotations:
    backstage.io/techdocs-ref: dir:.
  tags:
  - #used for searching. this can be free form such as 'react' or 'java' or 'c#' or 'aws' or 'azure' etc
  - #must be lower case
  - #cannot be empty, remove entire block if there are no tags
spec:
  type: # 'Reference Application' or 'Reference Infrastructure' or 'Code Library' or 'Utility' or 'Template'
  owner: parivedasolutions #do not change
  lifecycle: # 'Development' or 'Production'
  system: # 'Internal' or 'Client'
  `;
}

function getMkDocsConfig() {
  return `
# This is an example of mkdocs.yml.
## site_name: The name of your documentation site
## site_description: A short description of your site
# Navigation
## nav: Defines the structure of your documentation. You can organize the navigation by specifying the order of the pages and how they are grouped.
# Theme
## theme: Specifies the visual theme of your site. You can choose from built-in themes or customize one. You can also configure theme-related settings like colors, fonts, and layout.
# Markdown
## markdown_extensions: Lists the extensions to be used for rendering Markdown files. MkDocs supports various extensions that can add extra functionality like tables, footnotes, etc.
# Plugins
## plugins:  Specifies plugins to extend MkDocs' functionality. Examples include search, SEO optimization, and more.
# Extra Configs
## extra_css: Allows you to include additional CSS files.
## extra_javascript: Allows you to include additional JavaScript files.
## extra: Any additional metadata you want to include in the site configuration.
# For more information about MkDocs feature, please see https://www.mkdocs.org/user-guide/



site_name: Your Backstage Component Name # Required, and should be a string that is used as the main title for the project documentation.
theme:
    name: material # Alter how the documentation is displayed by changing the theme
nav:
    - Home: index.md  # This will be your HomePage
    - About: about.md
    - API Reference: # This is how you add pages to your documentation.
        - Authentication: api/auth.md
        - Users: api/users.md
markdown_extensions: # Enable a list of extensions beyond the ones that MkDocs uses
    - smarty
    - codehilite
plugins:
    - techdocs-core # This is the base Mkdocs plugin when using MKdocs. plugins MUST include techops-core
    - search
    - mkdocs-awesome-pages-plugin
  `;
}
